import { useRef, useEffect } from 'react'

import debounce from 'lodash/debounce'

import detect from './detect'

let cache = {}
let persistantCache = {}

let root = false
let ignoreScrollResize = true

const dimension = () => {
  if (!process.browser)
    return { width: 1440, height: 820, outerWidth: 1440, scrollWidth: 0 }

  if (cache.dimension) return cache.dimension

  var dimension = {
    width: !root ? Math.min(window.innerWidth, document.documentElement.clientWidth) : root.offsetWidth,
    innerWidth: document.body.offsetWidth,
    height: Math.min(window.innerHeight, document.documentElement.clientHeight)
    // height: !root ? window.innerHeight : root.offsetHeight
  }

  // alert(document.documentElement.clientHeight + '__' + dimension.height + '__' + screen.height + '__' + window.devicePixelRatio)

  dimension.scrollWidth = dimension.width - dimension.innerWidth

  // Ignore ios resize on scroll (wheen bottom bar disappear)

  if (ignoreScrollResize &&
    !detect.desktop &&
    persistantCache.dimension &&
    persistantCache.dimension.width === dimension.width)
    dimension.height = persistantCache.dimension.height

  dimension.ratio = dimension.width / dimension.height

  cache.dimension = dimension
  persistantCache.dimension = dimension

  return dimension
}

const width = () => dimension().width
const height = () => dimension().height
const scrollWidth = () => dimension().scrollWidth
const outerWidth = () => dimension().outerWidth
// const testMQ = (mq) => () => cache[mq] || (cache[mq] = window.matchMedia(mq).matches)

const clear = (force) => {
  cache = {}
  if (force === true) persistantCache = {}
}

let listeners = []

const resize = (force) => {
  resizeDebounce()
}

const resizeDebounce = debounce(() => {
  clear()
  listeners.forEach((listener) => listener && listener.resize && listener.resize())
}, 100)

process.browser && window.addEventListener('resize', resize)

const add = (listener) => listeners.push(listener)
const remove = (listener) => (listeners = listeners.filter(item => item !== listener))

const react = (Target) => {
  const componentDidMount = Target.prototype.componentDidMount
  Target.prototype.componentDidMount = function () {
    add(this)
    this.resize && this.resize()
    componentDidMount && componentDidMount.call(this)
  }

  const componentWillUnmount = Target.prototype.componentWillUnmount
  Target.prototype.componentWillUnmount = function () {
    remove(this)
    componentWillUnmount && componentWillUnmount.call(this)
  }

  return Target
}

const useResize = (callback) => {
  const savedCallback = useRef({ resize: null })

  useEffect(() => {
    callback()
    add(savedCallback.current)
    return () => remove(savedCallback.current)
  }, [])

  useEffect(() => {
    savedCallback.current.resize = callback
  }, [callback])
}

const setRoot = r => (root = r)
const setIgnoreScrollResize = i => (ignoreScrollResize = !!i)

export { useResize }

export default {
  dimension,
  scrollWidth,
  outerWidth,
  width,
  height,
  clear,
  react,
  useResize,
  add,
  remove,
  setRoot,
  setIgnoreScrollResize,
  resize
}
