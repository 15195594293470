import React from 'react'
import PropTypes from 'prop-types'

import Head from 'next/head'

const Meta = ({ title, description, image, keywords }) => {
  if (!title) title = 'Period • Paris'
  if (!description) description = ('We are creative partners to daring brands, crafting innovative solutions to solve business problems — merging strategy, design and tech.')
  if (!image) image = 'http://weareperiod.co/images/share.png'

  return (
    <Head>
      <title>{title}</title>
      <base key='base' href='/' />
      <meta key='charSet' charSet='utf-8' />
      <meta key='description' name='description' content={ description } />
      <meta key='X-UA-Compatible' httpEquiv='X-UA-Compatible' content='IE=edge,chrome=1' />
      {keywords && <meta key='keywords' name='keywords' content={ keywords } />}
      {/* <meta key='viewport' name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no' /> */}
      <meta key='viewport' name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0, shrink-to-fit=no' />
      {renderOpenGraph(title, description, image)}
      {renderIcons()}
    </Head>
  )
}

Meta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string
}

const renderIcons = () => {
  return (
    <React.Fragment key='icons'>
      <link rel='icon' type='image/png' sizes='16x16' href='/images/favicon.png' />
      <link rel='icon' type='image/png' sizes='32x32' href='/images/favicon.png' />
    </React.Fragment>
  )
}

const renderOpenGraph = (title, description, image) => {
  return (
    <React.Fragment key='og'>
      <meta key='og:type' property='og:type' content='website' />
      <meta key='og:site_name' property='og:site_name' content={ title } />
      <meta key='og:title' property='og:title' content={ title } />
      <meta key='og:description' property='og:description' content={ description } />
      <meta key='og:image' property='og:image' content={ image } />
      <meta key='og:image:width' property='og:image:width' content='1200' />
      <meta key='og:image:height' property='og:image:height' content='600' />

      <meta key='twitter:card' name='twitter:card' content='summary_large_image' />
      <meta key='twitter:title' name='twitter:title' content={ title } />
      <meta key='twitter:image' name='twitter:image' content={ image } />

      <meta key='itemProp:name' itemProp='name' content={ title } />
      <meta key='itemProp:description' itemProp='description' content={ description } />
    </React.Fragment>
  )
}

export default Meta
